//jQuery.noConflict();
const $ = require("jquery");
(function ($) {
    "use strict";
    var AppBack = {

        ajax: null,

        init: function () {
            var self = this;

            self.Language.init();
            self.TableShop.init();
            self.ColorPicker.init();
            self.Content.init();
            self.Operation.init();
            self.Order.init();
            self.OperationFurniture.init();
            self.OrderFurniture.init();
            self.Quota.init();
            self.Page.init();
        },

        Language: {
            selectors: {
                form: '#language-switcher form',
                languageField: '#language-switcher form .field-language input'
            },

            init: function init() {
                var self = this;
                var currentUrl = window.location.href;
                var formAction = $(self.selectors.form).attr('action');

                $(self.selectors.form).find('input').click(function (e) {
                    e.preventDefault();
                    var language = $(this).val();
                    $.ajax({
                        url: formAction,
                        type: 'POST',
                        data: 'switch-language=' + language,
                        success: function success(data, status) {
                            window.location.href = currentUrl;
                        },

                        error: function error(resultat, status, erreur) {
                            //console.log('error');
                        },

                        complete: function complete(resultat, status) {
                            //console.log('complete');
                        }

                    });
                });
            }
        },

        Content: {
            selectors: {},

            init: function () {
                var self = this;

            },

            /*Dropdown: {
                selectors: {
                    elt: '.dropdown-custom'
                },

                init: function init() {
                    var self = this;

                    $(self.selectors.elt).find('.dropdown-head').click(function (e) {
                        e.preventDefault();

                        var $dropdown = $(this).parent(self.selectors.elt);
                        if (!$dropdown.hasClass('open')) {
                            $dropdown.find('.dropdown-content').slideDown(300);
                            $dropdown.addClass('open');
                        } else {
                            $dropdown.find('.dropdown-content').slideUp(300);
                            $dropdown.removeClass('open');
                        }
                    });

                    $(self.selectors.elt).clickoutside(function () {
                        $(this).removeClass('open').find('.dropdown-content').hide();
                    });
                }
            }*/
        },

        TableShop: {
            selectors: {},

            init: function () {
                var self = this;

                $('#table').each(function() {
                    var $this = $(this);
                    if($this.find('.no-results').length < 1) {
                        $this.DataTable({
                            paging: false,
                            searching: false,
                            counting: false,
                            info: false
                        });
                    }

                    var element = $this.find("tbody > tr").length;
                    if($this.find('.no-results').length > 0) {
                        element = 0;
                    }
                    //$("#totalOnPage").empty();
                    //$("#totalOnPage").append(element);
                });

                /*$('.animation-list').each(function() {
                    var nb = $(this).find('.animation-item').length;
                    $("#totalOnPage").empty();
                    $("#totalOnPage").append(nb);
                });*/

                $('.table-resize').each(function() {
                    if($(this).find('.no-results').length > 0) {
                        var nb = 0;
                    }
                    else {
                        var nb = $(this).find('tbody > tr').length;
                    }

                    //$("#totalOnPage").empty();
                    //$("#totalOnPage").append(nb);
                });
                
                //$('.table-resize').resizableColumns();
                /*$('#table').tablesorter({
                    widgets: ['zebra', 'resizable'],
                    widgetOptions: {
                      resizable_addLastColumn : true,
                      resizable_widths : [ '100px', '60px', '30px', '50px', '60px', '140px' ]
                    }
                });*/
            },
        },

        Operation: {
            selectors: {},
            valid: true,
            init: function () {
                var self = this;
                self.refrigerationBinByAgency();
                self.shopCart();
                self.attributeShopsFinalSubmit();
            },
            refrigerationBinByAgency: function() {
                var self = this;
                $(document).on('change', '.product-refrigeratorbin .operation-select-agencies input', {}, function () {
                    var formToken = $('#refrigerator_bin_operation__token').val();
                    var formToken2 = $('[name="_token"]').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        var fieldname = $(this).attr('name');
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                if(fieldname.substring(fieldname.length -2) == '[]') {
                                    if(data[fieldname] == undefined) {
                                        data[fieldname] = [];
                                    }
                                    data[fieldname].push($(this).val());
                                }
                                else {
                                    data[fieldname] = $(this).val();
                                }
                            }
                        }
                        else {
                            data[fieldname] = $(this).val();
                        }
                    });
                    data['refrigerator_bin_operation[_token]'] = '';
                    data['_token'] = '';
                    if(self.ajax) { self.ajax.abort(); }
                    self.ajax = $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        /*
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                        },
                        */
                        success: function success(html) {
                            var selectRefBins = $(html).find('.operation-select-refrigeratorbins');
                            $('#refrigerator_bin_operation__token').val(formToken);
                            $('[name="_token"]').val(formToken2);
                            $('.operation-select-refrigeratorbins').html(selectRefBins.html());
                            AppBack.DropdownBis.init();
                        }
                    });
                });
            },
            shopCart: function() {
                var self = this;
                $(document).on('click', '.operation-shop-attribute .cart-add', {}, function (e) {
                    e.preventDefault();
                    let $this = $(this);

                    var opeId = $(this).attr('data-ope');
                    var shopId = $(this).attr('data-shop');
                    $.ajax({
                        url: '/operation-api/shop-cart-add/' + opeId + '/' + shopId,
                        beforeSend: function() {
                            $('.shops-list-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.ct-cart-add.item-' + shopId).removeClass('show').addClass('hide');
                            $('.ct-cart-delete.item-' + shopId).removeClass('hide').addClass('show');
                            self.refrehShopCartList(opeId);
                            $this.closest(".shop-row").addClass("selected");
                        }
                    });
                });

                $(document).on('click', '.operation-shop-attribute .cart-delete', {}, function (e) {
                    e.preventDefault();
                    let $this = $(this);

                    var opeId = $(this).attr('data-ope');
                    var shopId = $(this).attr('data-shop');
                    $.ajax({
                        url: '/operation-api/shop-cart-delete/' + opeId + '/' + shopId,
                        beforeSend: function() {
                            $('.shops-list-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.ct-cart-delete.item-' + shopId).removeClass('show').addClass('hide');
                            $('.ct-cart-add.item-' + shopId).removeClass('hide').addClass('show');
                            $("form[name='operation_shops_attribute']").find('input[value="' + shopId + '"]').prop('checked',false).change();
                            self.refrehShopCartList(opeId);
                            $this.closest(".shop-row").removeClass("selected");
                        }
                    });
                });
                // Page liste des magasins d'une opération
                $(document).on('click', '.shops-list-wrapper #shops-list .cart-delete', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var shopId = $(this).attr('data-shop');
                    $.ajax({
                        url: '/operation-api/shop-cart-delete/' + opeId + '/' + shopId,
                        beforeSend: function() {
                            $('.shops-list-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.ct-cart-delete.item-' + shopId).removeClass('show').addClass('hide');
                            $('.ct-cart-add.item-' + shopId).removeClass('hide').addClass('show');
                            $("form[name='operation_shops_attribute']").find('input[value="' + shopId + '"]').prop('checked',false).change();
                            self.refrehShopCartList(opeId);
                            $('.ct-cart-add.item-' + shopId).closest(".shop-row").removeClass("selected");
                        }
                    });
                });
            },
            refrehShopCartList: function(opeId) {
                var self = this;
                $.ajax({
                    url: '/operation-api/shop-cart-list/' + opeId,
                    beforeSend: function() {
                    },
                    success: function success(html) {
                        $('#shops-list').replaceWith(html);
                        $('.shops-list-wrapper').removeClass('loading');
                    }
                });
            },
            attributeShopsFinalSubmit: function() {
                var self = this;
                $(document).on('click', '.form-custom.attribute-shops .footer-actions button', {}, function (e) {
                    $('#operation_shops_attribute_isSubmit').val(1);
                });
            },
        },
        OperationFurniture: {
            selectors: {},

            valid: true,

            init: function () {
                var self = this;
                self.cart();
                self.attributeFinalSubmit();
            },
            cart: function()
            {
                var self = this;
                $(document).on('click', '.operation-furniture-attribute .cart-add', {}, function (e) {
                    e.preventDefault();
                    let $this = $(this);

                    var opeId = $(this).attr('data-ope');
                    var furnitureId = $(this).attr('data-furniture');
                    $.ajax({
                        url: '/operation-furniture-api/furniture-cart-add/' + opeId + '/' + furnitureId,
                        beforeSend: function() {
                            $('.furnitures-list-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.ct-cart-add.item-' + furnitureId).removeClass('show').addClass('hide');
                            $('.ct-cart-delete.item-' + furnitureId).removeClass('hide').addClass('show');
                            self.refrehCartList(opeId);
                            $this.closest(".furniture-row").addClass("selected");
                        }
                    });
                });

                $(document).on('click', '.operation-furniture-attribute .cart-delete', {}, function (e) {
                    e.preventDefault();
                    let $this = $(this);
                    
                    var opeId = $(this).attr('data-ope');
                    var furnitureId = $(this).attr('data-furniture');
                    $.ajax({
                        url: '/operation-furniture-api/furniture-cart-delete/' + opeId + '/' + furnitureId,
                        beforeSend: function() {
                            $('.furnitures-list-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.ct-cart-delete.item-' + furnitureId).removeClass('show').addClass('hide');
                            $('.ct-cart-add.item-' + furnitureId).removeClass('hide').addClass('show');
                            $("form[name='operation_furnitures_attribute']").find('input[value="' + furnitureId + '"]').prop('checked',false).change();
                            //$('.product-calendar.item-' + furnitureId).replaceWith(html);
                            self.refrehCartList(opeId);
                            $this.closest(".furniture-row").removeClass("selected");
                        }
                    });
                });

                // Page liste des fournitures d'une opération
                $(document).on('click', '.furnitures-list-wrapper #furnitures-list .calendar-delete', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var furnitureId = $(this).attr('data-furniture');
                    $.ajax({
                        url: '/operation-furniture-api/furniture-cart-delete/' + opeId + '/' + furnitureId,
                        beforeSend: function() {
                            $('.furnitures-list-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.ct-cart-delete.item-' + furnitureId).removeClass('show').addClass('hide');
                            $('.ct-cart-add.item-' + furnitureId).removeClass('hide').addClass('show');
                            $("form[name='operation_furnitures_attribute']").find('input[value="' + furnitureId + '"]').prop('checked',false).change();
                            self.refrehCartList(opeId);
                            $('.ct-cart-add.item-' + furnitureId).closest(".furniture-row").removeClass("selected");
                        }
                    });
                });
            },
            refrehCartList: function(opeId) {
                var self = this;
                $.ajax({
                    url: '/operation-furniture-api/furniture-cart-list/' + opeId,
                    beforeSend: function() {

                    },
                    success: function success(html) {
                        $('#furnitures-list').replaceWith(html);
                        //self.OperationFurniture.init();
                        //self.furnitureCalendar();
                        $('.furnitures-list-wrapper').removeClass('loading');
                    }
                });
            },
            attributeFinalSubmit: function() {
                var self = this;
                $(document).on('click', '.form-custom.attribute-furnitures .footer-actions button', {}, function (e) {
                    $('#operation_furnitures_attribute_isSubmit').val(1);
                });
            },
            Picker: {
                selectors: {},

                init: function () {
                    var self = this;
                    self.createDatepicker();
                    self.createDatepickerV2();
                    self.minMaxPerDay();
                },

                minMaxPerDay: function() {
                    var minTime = $('.minMaxPerDay').attr('data-min'),
                        maxTime = $('.minMaxPerDay').attr('data-max');
                    if(minTime != '' && maxTime != '' && !isNaN(minTime) && !isNaN(maxTime) && minTime == maxTime) {
                        if(minTime != 0 && maxTime != 0) {
                            var element = $('.spe-timepicker.time-start');
                            var perDay = parseFloat(minTime);

                            var hours = Math.floor(perDay);
                            var decimal = perDay - Math.floor(perDay);
                            decimal = decimal * 60;

                            element.each(function() {
                                var finalTime = $(this).timepicker('getTime');
                                if(finalTime != undefined) {
                                    finalTime.setHours(finalTime.getHours() + hours);
                                    finalTime.setMinutes(finalTime.getMinutes() + decimal);

                                    $(this).closest('.ct-form-dates').find('.time-end').val($(this).timepicker().format(finalTime));
                                }
                            });
                        }
                    }
                },

                createDatepicker: function()
                {
                    var self = this;

                    var availableDates = $('#operation_order_furniture_availableDates').val();
                    if(availableDates != null) {
                        availableDates = $.parseJSON(availableDates);
                        $('.spe-datepiker').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            beforeShowDay: function (date) {
                                var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                if (availableDates.indexOf(allDates) != -1) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            }
                        });
                        $(document).on('change', '.spe-datepiker', function(){
                            if($(this).parent().hasClass( "halfday" )) {
                                AppBack.Order.validatorHalfDay();
                            }
                        });
                    }
                },
                createDatepickerV2: function()
                {
                    var self = this;

                    var minBeginActionDate = $('#operation_order_furniture_minBeginActionDate').val();
                    var maxBeginActionDate = $('#operation_order_furniture_maxBeginActionDate').val();

                    var unavailableDateStart = $('#operation_order_furniture_unavailableDateStart').val();
                    if(minBeginActionDate != null && maxBeginActionDate != null) {
                        unavailableDateStart = $.parseJSON(unavailableDateStart);
                        $('.spe-datepiker-start').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            startDate: new Date(minBeginActionDate),
                            endDate: new Date(maxBeginActionDate),
                            beforeShowDay: function (date) {
                                if(unavailableDateStart != null) {
                                    var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    if (unavailableDateStart.indexOf(allDates) != -1) {
                                        return false;
                                    }
                                    else {
                                        return true;
                                    }
                                }
                                else {
                                    return true;
                                }
                            }
                        });
                        $(document).on('change', '.spe-datepiker-start', function(){
                            AppBack.Order.validatorDatesBin();
                        })
                    }

                    var minEndActionDate = $('#operation_order_furniture_minEndActionDate').val();
                    var maxEndActionDate = $('#operation_order_furniture_maxEndActionDate').val();

                    var unavailableDateEnd = $('#operation_order_furniture_unavailableDateEnd').val();
                    if(minEndActionDate != null && maxEndActionDate != null) {
                        unavailableDateEnd = $.parseJSON(unavailableDateEnd);
                        var recoveryDateSplit = $('#operation_order_furniture_recoveryDateCustom').val().split('-'),
                            dateDay = recoveryDateSplit[0],
                            dateMonth = recoveryDateSplit[1],
                            dateYear = recoveryDateSplit[2];

                        $('.spe-datepiker-end').each(function () {
                            if($(this).hasClass('recovery-type-2')) {
                                minEndActionDate = dateYear + '-' + dateMonth + '-' + dateDay;
                            }
                            else if($(this).hasClass('recovery-type-3')) {
                                maxEndActionDate = dateYear + '-' + dateMonth + '-' + dateDay;
                            }
                            $(this).datepicker({
                                language: 'fr',
                                format: 'dd-mm-yyyy',
                                startDate: new Date(minEndActionDate),
                                endDate: new Date(maxEndActionDate),
                                beforeShowDay: function (date) {
                                    if(unavailableDateEnd != null) {
                                        var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                        if (unavailableDateEnd.indexOf(allDates) != -1) {
                                            return false;
                                        }
                                        else {
                                            return true;
                                        }
                                    }
                                    else {
                                        return true;
                                    }
                                }
                            });
                        });
                        $(document).on('change', '.spe-datepiker-end:not(.recoveryDate)', function(){
                            AppBack.Order.validatorDatesBin();
                        })
                    }
                },
            },
        },
        Order: {
            selectors: {},

            valid: true,

            init: function () {
                var self = this;

                $("#user1_shopsInCharge").select2({
                    multiple: 'multiple'
                });

                self.Autocomplete();
                self.changeShop();
                self.changeShopsign();
                self.changeOrderRegion();
                self.changeShopInfos();
                self.changeDates();
                self.addDate();
                self.removeDate();
                self.refrigeratorSubmitStep2();
                self.refrigeratorbinChangDeliveryeDate();
                self.addCustomShop();

                $(document).on('change', '.profiles select', function() {
                    self.changeProfile();
                });

                var nbSpeakersInit = 1;
                var totalTimeInit = 0;
                $('.ct-form-dates').each(function() {
                    var diffDatesInit = parseFloat($(this).find('.diff-dates input').val());
                    
                    /*if($(this).find('.nb-speaker').length > 0) {
                        nbSpeakers = $(this).find('.nb-speaker').val();
                    }*/
                    totalTimeInit += diffDatesInit * nbSpeakersInit;
                });

                $('.total-time').text(totalTimeInit);
                if(totalTimeInit > 1) {
                    $('.ct-total-time .plurial').addClass('visible'); 
                }
                else {
                    $('.ct-total-time .plurial').removeClass('visible'); 
                }

                $('.command-container form').submit(function() {
                    self.validatorDates(true);

                    if(self.valid) {
                        $(this).find('.footer-actions button').attr('disabled', 'disabled');
                    }
                    return self.valid;
                });

                $(".form-dates input").keydown(false);

            },

            changeShop: function()
            {
                var self = this;
            
                //var $shop = $('#operation_order_shop');
                $(document).on('change', '#operation_order_shop', {}, function () {
                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['operation_order[_token]'] = '';
                    data['operation_order[shopInfoName]'] = '';
                    data['operation_order[shopInfoAddress]'] = '';
                    data['operation_order[shopInfoPhone]'] = '';
                    data['operation_order[shopInfoMail]'] = '';
                    
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').addClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('.filter-reset').css('display','inline-flex');
                            $form.find('#shop-infos input').each(function () {
                                if($(this).val() == '') {
                                    //$(this).attr('placeholder', $(this).closest('[data-empty]').attr('data-empty'));
                                }
                            });

                            $('.step-tabs .step-item.active + .step-item').addClass('clickable');
                        }
                    });
                });

            },

            changeShopInfos: function()
            {
                var self = this;

                $(document).on('click', '#shop-infos .close-edit', {}, function () {
                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order[_token]'] = '';
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').addClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('.filter-reset').css('display','inline-flex');
                            $('.step-tabs .step-item.active + .step-item').addClass('clickable');
                        }
                    });
                });

            },

            changeShopsign: function()
            {
                var self = this;

                $(document).on('change', '#operation_order_shopSign', {}, function () {
                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order[_token]'] = '';
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            $form.find('#shop-infos input').each(function () {
                                $(this).val('');
                            });
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').removeClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('.filter-reset').css('display','inline-flex');
                        }
                    });
                });

            },

            changeOrderRegion: function()
            {
                var self = this;

                $(document).on('change', '#operation_order_region', {}, function () {
                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                            /*else {
                                data[$(this).attr('name')] = '';
                            }*/
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order[_token]'] = '';
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            $form.find('#shop-infos input').each(function () {
                                $(this).val('');
                            });
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').removeClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('.filter-reset').css('display','inline-flex');
                        }
                    });
                });

            },

            changeDates: function()
            {

                var self = this;

                //Nombre d'intervenants
                $(document).on('change', '.nb-speaker', {}, function () {
                    if($(this).closest('.ct-form-dates').find('.date-start').val() != '' && $(this).closest('.ct-form-dates').find('.date-end').val() != '') {
                        $(this).closest('.ct-form-dates').find('.end-auto').change();
                    }
                });

                if ($('#operation_order_availableDates').length > 0) {
                    self.Picker.init();
                    $(document).on('change', '.end-auto', {}, function () {
                        var $this = $(this);

                        if($this.hasClass('date-start')) {
                            if($this.closest('.ct-form-dates').find('.date-end').val() == '') {
                                $this.closest('.ct-form-dates').find('.date-end').val($this.val());
                                $this.closest('.ct-form-dates').find('.nbDay').val(1).attr('value', 1);
                            }
                        }

                        $('.ct-form-dates').removeClass('error');
                        $('.error-form').hide();
                        
                        var formToken = $('#operation_order__token').val();
                        var $form = $(this).closest('form');
                        var data = {};
                        $form.find('input, select, textarea').each(function () {
                            if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                                if($(this).prop('checked') == true) {
                                    data[$(this).attr('name')] = $(this).val();
                                }
                                /*else {
                                    data[$(this).attr('name')] = '';
                                }*/
                            }
                            else {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        });
                        data['operation_order[_token]'] = '';
                        if(self.ajax) { self.ajax.abort(); }
                        self.ajax = $.ajax({
                            url: $form.attr('action'),
                            type: $form.attr('method'),
                            data: data,
                            beforeSend: function() {
                                $this.closest('.ct-form-dates').addClass('loading');
                                $('.datepicker').hide();
                                $('.step-actions, .footer-actions').addClass('disabled');
                            },
                            success: function success(html) {
                                var cmdStep2 = $(html).find('#cmd-step2');
                                $('#operation_order__token').val(formToken);
                                $('#cmd-step2').html(cmdStep2.html());
                                self.Picker.init();
                                $this.closest('.ct-form-dates').removeClass('loading');
                                $('.ct-form-dates input').attr('autocomplete', 'off');

                                if($('.multiple-no').length > 0) {
                                    $('.ct-form-dates .date-start').val($('.ct-form-dates:first-child').find('.date-start').val());
                                    $('.ct-form-dates .date-end').val($('.ct-form-dates:first-child').find('.date-end').val());

                                    $('.ct-form-dates .t-start').val($('.ct-form-dates:first-child').find('.t-start').val());
                                    $('.ct-form-dates .t-end').val($('.ct-form-dates:first-child').find('.t-end').val());

                                    var nbHours = $('.ct-form-dates:first-child').find('.diff-dates input').val();
                                    var nbIntervenants = $('.ct-form-dates:first-child').find('.nb-speaker').val();
                                    if(isNaN(nbIntervenants)) {
                                        nbIntervenants = 1;
                                    }
                                    $('.ct-form-dates:first-child ~ .ct-form-dates .diff-dates input').each(function() {
                                        var rowSpeaker = $(this).closest('.ct-form-dates').find('.nb-speaker').val();
                                        if(isNaN(rowSpeaker)) {
                                            rowSpeaker = 1;
                                        }
                                        $(this).val(nbHours/nbIntervenants*rowSpeaker);
                                    });
                                }


                                self.validatorDates();
                                $(".form-dates input").keydown(false);

                                $('.step-actions, .footer-actions').removeClass('disabled');
                                
                            }
                        });
                    });
                }

            },

            changeProfile: function()
            {
                var self = this;

                // Si un profil est sélectionné plusieurs fois
                if($('.multiple-no .profiles').length > 0) {
                    $('.profiles').removeClass('error');
                    $('.error-profile').hide();
                    var profiles = [];
                    $('.profiles').each(function(i) {
                        profiles[i] = $(this).find('select').val();
                    });

                    $('.profiles').each(function(i) {
                        var $profile = $(this);
                        var n = 0;
                        for (var j = 0; j <= profiles.length; j++) {
                            if($(this).find('select').val() == profiles[j]) {
                                n++;
                            }
                        }
                        if(n > 1) {
                            $profile.addClass('error');
                            $('.error-profile').css('display','table');
                            self.valid = false;
                            //return false;
                        }
                    });

                }
            },

            addDate: function()
            {

                var self = this;

                //$('#operation_order_addDate').click(function() {
                $(document).on('click', '#operation_order_addDate', {}, function () {
                    $('#operation_order_addDateValue').val(1);
                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                            /*else {
                                data[$(this).attr('name')] = '';
                            }*/
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order[_token]'] = '';
                    if(self.ajax) { self.ajax.abort(); }
                    self.ajax = $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.table-dates').addClass('loading');
                            $('.step-actions, .footer-actions').addClass('disabled');
                        },
                        success: function success(html) {
                            var cmdStep2 = $(html).find('#cmd-step2');
                            $('#operation_order__token').val(formToken);
                            $('#operation_order_addDateValue').val('');

                            //$(cmdStep2).find('#operation_order_addDateValue').val('');
                            $('#cmd-step2').html(cmdStep2.html());

                            $('#cmd-step2').find('.nb-speaker').each(function() {
                                if($(this).val() == '') {
                                    $(this).val(1);
                                }
                            });

                            $('#cmd-step2').find('.spe-timepicker').each(function() {
                                if($(this).val() == '') {
                                    $(this).val('00:00');
                                }
                            });

                            $('#cmd-step2').find('.diff-dates input').each(function() {
                                if($(this).val() == '') {
                                    $(this).val(0);
                                }
                            });

                            self.Picker.init();
                            $('.table-dates').removeClass('loading');


                            $('.ct-form-dates:last-child .date-start').val($('.ct-form-dates:last-child').prev().find('.date-start').val());
                            $('.ct-form-dates:last-child .date-end').val($('.ct-form-dates:last-child').prev().find('.date-end').val());

                            $('.ct-form-dates:last-child .t-start').val($('.ct-form-dates:last-child').prev().find('.t-start').val());
                            $('.ct-form-dates:last-child .t-end').val($('.ct-form-dates:last-child').prev().find('.t-end').val());
                            
                            var lastTotal = parseFloat($('.ct-form-dates:last-child').prev().find('.diff-dates input').val());
                            var lastNbSpeaker = parseFloat($('.ct-form-dates:last-child').prev().find('.nb-speaker').val());

                            if(isNaN(lastNbSpeaker)) {
                                lastNbSpeaker = 1;
                            }

                            $('.ct-form-dates:last-child .diff-dates input').val(lastTotal/lastNbSpeaker).attr('value', lastTotal/lastNbSpeaker);

                            $('.ct-form-dates input').attr('autocomplete', 'off');
                            self.validatorDates();
                            $(".form-dates input").keydown(false);

                            $('.step-actions, .footer-actions').removeClass('disabled');
                        }
                    });
                });

            },

            removeDate: function()
            {

                var self = this;

                $(document).on('click', '.btn-remove-date', {}, function (e) {
                    e.preventDefault();
                    var itemId = $(this).attr('href');
                    var toExclude = [
                        'operation_order[dateActionStart_'+ itemId + ']',
                        'operation_order[dateActionEnd_'+ itemId + ']',
                        'operation_order[hourActionStart_'+ itemId + ']',
                        'operation_order[hourActionEnd_'+ itemId + ']',
                        'operation_order[nbTime_'+ itemId + ']',
                        'operation_order[nbSpeaker_'+ itemId + ']',
                        'operation_order[speaker_'+ itemId + ']'
                    ];

                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        var fieldName = $(this).attr('name');
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                            /*else {
                                data[$(this).attr('name')] = '';
                            }*/
                        }
                        else {
                            if(jQuery.inArray(fieldName, toExclude) == -1) {
                                data[fieldName] = $(this).val();
                            }
                        }     
                    });
                    data['operation_order[_token]'] = '';
                    if(self.ajax) { self.ajax.abort(); }
                    self.ajax = $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.table-dates').addClass('loading');
                            $('.step-actions, .footer-actions').addClass('disabled');
                        },
                        success: function success(html) {
                            var cmdStep2 = $(html).find('#cmd-step2');
                            $('#operation_order__token').val(formToken);
                            $('#cmd-step2').html(cmdStep2.html());
                            self.Picker.init();
                            $('.table-dates').removeClass('loading');

                            $('.ct-form-dates input').attr('autocomplete', 'off');
                            self.validatorDates();
                            $(".form-dates input").keydown(false);
                            $('.step-actions, .footer-actions').removeClass('disabled');
                        }
                    });
                });
            },

            validatorDates: function(onSubmit, halfday)
            {
                var self = this;

                self.valid = true;

                var arrayDates = [];
                var totalTime = 0;

                $('.ct-form-dates').each(function(index) {

                    // Si profil sélectionné plusieurs fois
                    self.changeProfile();

                    // Si date de fin vide
                    if(!onSubmit) {
                        if($(this).find('.date-end').val() == '') {
                            $(this).find('.date-end').val($(this).find('.date-start').val());
                            $(this).find('.nbDay').val(1).attr('value', 1);
                        }
                    }
                    
                    // Si date de fin antérieure
                    var from = $(this).find('.date-start').val().split("-");
                    var hourFromSplited = $(this).find('.time-start').val().split(":");
                    var to = $(this).find('.date-end').val().split("-");
                    var hourToSplited = $(this).find('.time-end').val().split(":");
                    var diffDates = parseFloat($(this).find('.diff-dates input').val().replace(',', '.'));

                    //if(new Date(from[2], from[1] - 1, from[0]) > new Date(to[2], to[1] - 1, to[0])) {
                    if(diffDates <= 0) {
                        $(this).addClass('error');
                        $('.error-date').css('display','table');
                        self.valid = false;
                    }

                    // Si dates antérieures à la date du jour 
                    if($(this).find('.date-start').datepicker('getDate') != null && $(this).find('.date-end').datepicker('getDate') != null) {
                        if($(this).find('.date-start').datepicker('getDate').getTime() < Date.now() || $(this).find('.date-end').datepicker('getDate').getTime() < Date.now()) {
                            $(this).addClass('error');
                            $('.error-today').css('display','table');
                            self.valid = false;
                        }
                    }

                    // Si les dates se chevauchent
                    if($(this).find('.date-start').datepicker('getDate') != null && $(this).find('.date-end').datepicker('getDate') != null && $(".has-speaker").length < 1) {
                        var dStart = new Date(from[2], from[1], from[0], hourFromSplited[0], hourFromSplited[1]);
                        var dEnd = new Date(to[2], to[1], to[0], hourToSplited[0], hourToSplited[1]);
                        var arrayDatesI = 0;
                        if(arrayDates.length > 0) {
                            for (arrayDatesI = 0; arrayDatesI < arrayDates.length; ++arrayDatesI) {
                                if(dStart.getTime() == arrayDates[arrayDatesI][0].getTime() || (dStart > arrayDates[arrayDatesI][0] && dStart < arrayDates[arrayDatesI][1])) {
                                    $(this).addClass('error');
                                    $('.error-date-overlap').css('display','table');
                                    self.valid = false;
                                }
                            }
                        }
                        arrayDates.push([dStart, dEnd]);
                    }
                    

                    // Si date de début n'est pas dans l'intervalle du delivery delay
                    if($(this).find('.date-start').datepicker('getDate') != null) {
                        var daysUntil = ($(this).find('.date-start').datepicker('getDate').getTime() - Date.now() ) / (24*60*60*1000);
                        daysUntil = parseInt(daysUntil);
                        var deliveryDelay = $(this).closest('[data-delivery]').attr('data-delivery');
                        if(deliveryDelay != '') {
                            deliveryDelay = parseInt(deliveryDelay);
                            if(daysUntil < deliveryDelay) {
                                $(this).addClass('error');
                                $('.error-until').css('display','table');
                                self.valid = false;
                            }
                        }
                    }

                    if ( halfday && !onSubmit){
                        return;
                    }

                    // Multiplication par nb d'intervenants
                    var nbSpeakers = 1;
                    
                    if(!onSubmit) {
                        $(this).find('.diff-dates input').val(diffDates * nbSpeakers).attr('value', diffDates * nbSpeakers);
                    }

                    if($(this).closest('[data-durationType="hour"]').length) {
                        //if(diffDates * nbSpeakers > 0 && (diffDates * nbSpeakers < 2 || diffDates * nbSpeakers > 7)) {
                        if(diffDates * nbSpeakers > 0 && diffDates * nbSpeakers < 2) {
                            $(this).addClass('error');
                            $('.error-time').css('display','table');
                        }
                        /*if(diffDates * nbSpeakers < 2) {
                            checkTime = false;
                            self.valid = false;
                        }*/
                    }

                    totalTime += diffDates * nbSpeakers;

                    if(!onSubmit) {
                        $('.total-time').text(totalTime);
                        var plurial = $(this).find('.duration-type').text();
                        if(diffDates * nbSpeakers > 1) { 
                            $(this).find('.duration-type').addClass('visible');
                        }
                        else {
                            $(this).find('.duration-type').removeClass('visible');
                        }
                    }

                    if(onSubmit) {
                        if($(this).closest('#cmd-step2').find('.order-form-error ul li').length > 1) {
                            self.valid = false;
                        }
                    }
                });

                if(totalTime > 1) {
                    $('.ct-total-time .plurial').addClass('visible'); 
                }
                else {
                    $('.ct-total-time .plurial').removeClass('visible'); 
                }

                //return checkTime;
            },

            validatorDatesBin: function(onSubmit)
            {
                var self = this;
                self.valid = true;

                const fromDate = $('.date-start').datepicker('getDate');
                const toDate = $('.date-end').datepicker('getDate');
                const today = new Date();
                const diffToday = (fromDate - today) / (1000 * 60 * 60 * 24);
                $('.date-start').removeClass('error');
                $('.date-end').removeClass('error');
                $('.error-form').hide();

                // Si date de début n'est pas dans l'intervalle du delivery delay
                const deliveryDelay = parseInt($('[data-delivery]').attr('data-delivery'));


                if( diffToday < deliveryDelay) {
                    $('.date-start').addClass('error');
                    $('.error-until').show();
                    self.valid = false;
                }

                if ( toDate ){
                    //date de fin < au début
                    if( toDate < fromDate ) {
                        $('.date-end').addClass('error');
                        $('.error-date').show();
                        self.valid = false;

                        return false;
                    }

                    const durationType = $('[data-durationtype]').attr('data-durationtype');
                    const duration = parseInt($('[data-duration]').attr('data-duration'));
                    const durationMin = parseInt($('[data-duration-min]').attr('data-duration-min'));

                    let maxDurationDate = new Date(fromDate.getTime());
                    let minDurationDate = new Date(fromDate.getTime());

                    switch ( durationType ){
                        case 'month':
                            maxDurationDate.setMonth(fromDate.getMonth()+duration);
                            minDurationDate.setMonth(fromDate.getMonth()+durationMin);        
                        break;

                        case 'day':
                            maxDurationDate.setDate(fromDate.getDate()+duration);
                            minDurationDate.setDate(fromDate.getDate()+durationMin);        
                        break;
                    }

                    // Si la durée est insufisante
                    if ( durationMin ){
                        if( toDate < minDurationDate) {
                            $('.date-end').addClass('error');
                            $('.error-min-duration').show();
                            self.valid = false;
                        }
                    }
                }


                return self.valid;
            },

            validatorHalfDay: function(onSubmit){
                let $formDates = $('.ct-form-dates').first();
                let dateStart = $formDates.find('.date-start').datepicker('getDate');
                let dateEnd = $formDates.find('.date-end').datepicker('getDate');
                let timeStart = $formDates.find('.time-start').val().split(':');
                let timeEnd = $formDates.find('.time-end').val().split(':');
                let durationMax = parseInt($formDates.attr('data-duration'));
                let durationMin = parseInt($formDates.attr('data-duration-min'));
                let halfdayDuration = parseInt($formDates.attr('data-halfday-duration')); 
                $('.error-form').css('display', 'none');

                if ( !(dateStart && dateEnd && timeStart && timeEnd && halfdayDuration) ){
                    return;
                }

                dateStart.setHours(timeStart[0]);
                dateStart.setMinutes(timeStart[1]);
                dateEnd.setHours(timeEnd[0]);
                dateEnd.setMinutes(timeEnd[1]);

                let hours = (dateEnd - dateStart) / 36e5;
                let nbHalfDays = Math.ceil(hours/halfdayDuration);
                let nbDays = nbHalfDays/2;

                $formDates.find('.diff-dates input').val(nbDays.toString().replace('.', ','));
                $('.ct-total-time .total-time').html(nbDays.toString().replace('.', ','));
                
                if ( nbDays >= 2 ){
                    $('.ct-total-time .plurial').show();
                } else {
                    $('.ct-total-time .plurial').hide();
                }

                if ( durationMax > 0 && nbHalfDays > durationMax ){
                    $formDates.addClass('error');
                    $('.error-halfday-max').css('display','table');
                }
                if ( durationMin > 0 && nbHalfDays < durationMin ){
                    $formDates.addClass('error');
                    $('.error-halfday-min').css('display','table');
                }

               this.validatorDates(false, true);
            },

            Autocomplete: function () {
                var self = this;
                var formToken = $('#operation_order__token').val();
                //var $form = $(this).closest('form');
                var $form = $("form[name='operation_order']");
                var data = {};
                $form.find('select').each(function () {
                    data[$(this).attr('name')] = $(this).val();
                });
                data['operation_order[_token]'] = '';
                data['operation_order[operationId]'] =  $('#operation_order_operationId').val();
                var dataParams = jQuery.param(data);
                $("#operation_order_shopAutocomplete").autocomplete({
                    source: '/autocomplete/search-order-shop/?' + dataParams,
                    minLength: 2,
                    appendTo: '.autocomplete-list',
                    search: function() {
                        $(this).closest('.autocomplete-field').addClass('loading');
                    },
                    response: function(event, ui) {
                        $(this).closest('.autocomplete-field').removeClass('loading');
                        if (ui.content.length === 0) {
                            var noResult = { value:"", label: $(this).closest('.autocomplete-field').attr('data-empty') };
                            ui.content.push(noResult);
                        }
                    },
                    select: function( event, ui ) {
                        var formToken = $('#operation_order__token').val();
                        var $form = $(this).closest('form');
                        var data = {};
                        $form.find('input, select, textarea').each(function () {
                            if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                                if($(this).prop('checked') == true) {
                                    data[$(this).attr('name')] = $(this).val();
                                }
                                /*else {
                                    data[$(this).attr('name')] = '';
                                }*/
                            }
                            else {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        });
                        data['operation_order[_token]'] = '';
                        data['operation_order[shop]'] = ui.item.id;
                        data['operation_order[shopSign]'] = ui.item.shopSignId;
                        data['operation_order[region]'] = ui.item.regionId;
                        $.ajax({
                            url: $form.attr('action'),
                            type: $form.attr('method'),
                            data: data,
                            beforeSend: function() {
                                $('.command-filters').addClass('loading');
                            },
                            success: function success(html) {
                                var cmdStep1 = $(html).find('#cmd-step1');
                                $('#operation_order__token').val(formToken);
                                $('#cmd-step1').html(cmdStep1.html());
                                //$( "#operation_order_shopAutocomplete" ).val('');
                                self.init();
                                AppBack.Dropdown.create('.command-filters select', '.command-filters');
                                $('#shop-infos').addClass('editable');
                                $('.command-filters').removeClass('loading');
                                $('.filter-reset').css('display','inline-flex');
                                $form.find('#shop-infos input').each(function () {
                                    if($(this).val() == '') {
                                        //$(this).attr('placeholder', $(this).closest('[data-empty]').attr('data-empty'));
                                    }
                                });

                                $('.step-tabs .step-item.active + .step-item').addClass('clickable');

                            }
                        });
                        /*
                        var message = "Selected: " + ui.item.label + " aka " + ui.item.id ;
                        $( "<div>" ).text( message ).prependTo('#shop-autocomplete-list');
                        $( "#shop-autocomplete-list" ).scrollTop( 0 );
                        */
                    }
                });
            },

            refrigeratorSubmitStep2: function()
            {
                var self = this;

                $(document).on('click', '.refrigerator-container .next-step', {}, function () {
                    var dataStep = $(this).attr("data-step");
                    if ( $('.error').length ){
                        return;
                    }

                    $('#operation_order_formStep').val(dataStep);
                    //if(dataStep == 3) {
                        var formToken = $('#operation_order__token').val();
                        var $form = $(this).closest('form');
                        var data = {};
                        $form.find('input, select, textarea').each(function () {
                            if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                                if($(this).prop('checked') == true) {
                                    data[$(this).attr('name')] = $(this).val();
                                }
                            }
                            else {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        });
                        data['operation_order[_token]'] = '';
                        data['operation_order[formStep]'] = dataStep;
                        $.ajax({
                            url: $form.attr('action'),
                            type: $form.attr('method'),
                            data: data,
                            beforeSend: function() {
                                $('.command-filters').addClass('loading');
                                $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                            },
                            success: function success(html) {
                                var cmdStep = $(html).find('#cmd-step' + dataStep);
                                $('#operation_order__token').val(formToken);
                                $('#cmd-step' + dataStep).html(cmdStep.html());
                                $( "#operation_order_shopAutocomplete" ).val('');
                                self.Autocomplete();
                                self.Picker.init();
                                //AppBack.Dropdown.create('.command-filters select', '.command-filters');
                                //$('#shop-infos').removeClass('editable');
                                $('.command-filters').removeClass('loading');
                                $('.filter-reset').css('display','inline-flex');
                            }
                        });
                    //}
                });

            },
            refrigeratorbinChangDeliveryeDate: function()
            {
                var self = this;

                $(document).on('change', '#operation_order_deliveryDateCustom', {}, function () {
                    var dataStep = 3;
                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['operation_order[_token]'] = '';
                    data['operation_order[formStep]'] = dataStep;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep = $(html).find('#cmd-step' + dataStep);
                            var otherFields = $(html).find('#order-other-fields');
                            $('#operation_order__token').val(formToken);
                            $('#cmd-step' + dataStep).html(cmdStep.html());
                            $('#order-other-fields').html(otherFields.html());
                            self.Picker.init();
                            //AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            //$('#shop-infos').removeClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('.filter-reset').css('display','inline-flex');
                        },
                        complete: function(html) {
                            var deliveryDateSplit = $('.date-start').val().split('-'),
                                dateDay = deliveryDateSplit[0],
                                dateMonth = deliveryDateSplit[1],
                                dateYear = deliveryDateSplit[2];
                            //var newDateString = dateDay + '/' + dateMonth + '/' + dateYear;
                            var newDateString = dateDay + '-' + dateMonth + '-' + dateYear;
                            $('.table-custom .form-delivery-date').each(function() {
                                $(this).html(newDateString);
                            });
                        }
                    });
                });

                $(document).on('change', '#operation_order_recoveryDateCustom', {}, function () {
                    var dataStep = 3;
                    var recoveryDate = $(this).val();
                    var formToken = $('#operation_order__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['operation_order[_token]'] = '';
                    data['operation_order[formStep]'] = dataStep;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep = $(html).find('#cmd-step' + dataStep);
                            var otherFields = $(html).find('#order-other-fields');
                            $('#operation_order__token').val(formToken);
                            $('#cmd-step' + dataStep).html(cmdStep.html());
                            $('#order-other-fields').html(otherFields.html());
                            self.Picker.init();
                            //AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            //$('#shop-infos').removeClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('.filter-reset').css('display','inline-flex');
                        },
                        complete: function(html) {

                            var deliveryDateSplit = $('.date-start').val().split('-'),
                                dateDay = deliveryDateSplit[0],
                                dateMonth = deliveryDateSplit[1],
                                dateYear = deliveryDateSplit[2];
                            //var newDateString = dateDay + '/' + dateMonth + '/' + dateYear;
                            var newDateString = dateDay + '-' + dateMonth + '-' + dateYear;
                            $('.table-custom .form-delivery-date').each(function() {
                                $(this).html(newDateString);
                            });

                            var recoveryDateSplit = recoveryDate.split('-'),
                                dateDay = recoveryDateSplit[0],
                                dateMonth = recoveryDateSplit[1],
                                dateYear = recoveryDateSplit[2];
                            //var newDateString = dateYear + '-' + dateMonth + '-' + dateDay;
                            var newDateString =  dateDay + '-' + dateMonth + '-' + dateYear;
                            //$('#operation_order_recoveryDate').val(dateYear + '-' + dateMonth + '-' + dateDay);
                            $('.table-custom .form-control.recoveryDate').each(function() {
                                $(this).val(recoveryDate);
                            });
                        }
                    });
                });
            },
            Picker: {
                selectors: {},

                init: function () {
                    var self = this;
                    self.createDatepicker();
                    self.createDatepickerV2();
                    self.createTimepicker();
                    self.minMaxPerDay();
                },

                minMaxPerDay: function() {
                    var minTime = $('.minMaxPerDay').attr('data-min'),
                        maxTime = $('.minMaxPerDay').attr('data-max');
                    if(minTime != '' && maxTime != '' && !isNaN(minTime) && !isNaN(maxTime) && minTime == maxTime) {
                        if(minTime != 0 && maxTime != 0) {
                            var element = $('.spe-timepicker.time-start');
                            var perDay = parseFloat(minTime);

                            var hours = Math.floor(perDay);
                            var decimal = perDay - Math.floor(perDay);
                            decimal = decimal * 60;

                            element.each(function() {
                                var finalTime = $(this).timepicker('getTime');
                                if(finalTime != undefined) {
                                    finalTime.setHours(finalTime.getHours() + hours);
                                    finalTime.setMinutes(finalTime.getMinutes() + decimal);

                                    $(this).closest('.ct-form-dates').find('.time-end').val($(this).timepicker().format(finalTime));
                                }
                            });
                        }
                    }
                },

                createDatepicker: function()
                {
                    var self = this;

                    var availableDates = $('#operation_order_availableDates').val();
                    if(availableDates != null) {
                        availableDates = $.parseJSON(availableDates);
                        $('.spe-datepiker').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            beforeShowDay: function (date) {
                                var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                if (availableDates.indexOf(allDates) != -1) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            }
                        });
                        $(document).on('change', '.spe-datepiker', function(){
                            if($(this).parent().hasClass( "halfday" )) {
                                AppBack.Order.validatorHalfDay();
                            }
                        });
                    }
                },
                createDatepickerV2: function()
                {
                    var self = this;

                    var minBeginActionDate = $('#operation_order_minBeginActionDate').val();
                    var maxBeginActionDate = $('#operation_order_maxBeginActionDate').val();

                    var unavailableDateStart = $('#operation_order_unavailableDateStart').val();
                    if(minBeginActionDate != null && maxBeginActionDate != null) {
                        unavailableDateStart = $.parseJSON(unavailableDateStart);
                        $('.spe-datepiker-start').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            startDate: new Date(minBeginActionDate),
                            endDate: new Date(maxBeginActionDate),
                            beforeShowDay: function (date) {
                                if(unavailableDateStart != null) {
                                    var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    if (unavailableDateStart.indexOf(allDates) != -1) {
                                        return false;
                                    }
                                    else {
                                        return true;
                                    }
                                }
                                else {
                                    return true;
                                }
                            }
                        });
                        $(document).on('change', '.spe-datepiker-start', function(){
                            AppBack.Order.validatorDatesBin();
                        })
                    }

                    var minEndActionDate = $('#operation_order_minEndActionDate').val();
                    var maxEndActionDate = $('#operation_order_maxEndActionDate').val();

                    var unavailableDateEnd = $('#operation_order_unavailableDateEnd').val();
                    if(minEndActionDate != null && maxEndActionDate != null) {
                        unavailableDateEnd = $.parseJSON(unavailableDateEnd);
                        var recoveryDateSplit = $('#operation_order_recoveryDateCustom').val().split('-'),
                            dateDay = recoveryDateSplit[0],
                            dateMonth = recoveryDateSplit[1],
                            dateYear = recoveryDateSplit[2];

                        $('.spe-datepiker-end').each(function () {
                            if($(this).hasClass('recovery-type-2')) {
                                minEndActionDate = dateYear + '-' + dateMonth + '-' + dateDay;
                            }
                            else if($(this).hasClass('recovery-type-3')) {
                                maxEndActionDate = dateYear + '-' + dateMonth + '-' + dateDay;
                            }
                            $(this).datepicker({
                                language: 'fr',
                                format: 'dd-mm-yyyy',
                                startDate: new Date(minEndActionDate),
                                endDate: new Date(maxEndActionDate),
                                beforeShowDay: function (date) {
                                    if(unavailableDateEnd != null) {
                                        var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                        if (unavailableDateEnd.indexOf(allDates) != -1) {
                                            return false;
                                        }
                                        else {
                                            return true;
                                        }
                                    }
                                    else {
                                        return true;
                                    }
                                }
                            });
                        });
                        $(document).on('change', '.spe-datepiker-end:not(.recoveryDate)', function(){
                            AppBack.Order.validatorDatesBin();
                        })
                    }
                },

                createTimepicker: function()
                {
                    var self = this;

                    var configTimepicker = $('#operation_order_configTimepicker').val();
                    if(configTimepicker != null){
                        configTimepicker = $.parseJSON(configTimepicker);

                        var minHour = null;
                        var minMinutes = null;
                        var maxHour = null;
                        var maxMinutes = null;

                        if (configTimepicker['start']['minHour'] != undefined) {
                            minHour = configTimepicker['start']['minHour'];
                        }
                        if (configTimepicker['start']['minMinutes'] != undefined) {
                            minMinutes = configTimepicker['start']['minMinutes'];
                        }
                        if (configTimepicker['start']['maxHour'] != undefined) {
                            maxHour = configTimepicker['start']['maxHour'] ;
                        }
                        if (configTimepicker['start']['maxMinutes'] != undefined) {
                            maxMinutes = configTimepicker['start']['maxMinutes'];
                        }
                        $('.spe-timepicker.time-start').timepicker({
                            timeFormat: 'HH:mm',
                            interval: 30,
                            minHour: minHour,
                            minMinutes: minMinutes,
                            maxHour: maxHour,
                            maxMinutes: maxMinutes,
                            dynamic: false,
                            dropdown: true,
                            scrollbar: true,
                            change: function () {
                                var $this = $(this);
                                var formToken = $('#operation_order__token').val();
                                var $form = $(this).closest('form');
                                var data = {};
                                $form.find('input, select, textarea').each(function () {
                                    data[$(this).attr('name')] = $(this).val();
                                });
                                data['operation_order[_token]'] = '';
                                if($(this).hasClass( "end-auto" )) {
                                    if(self.ajax) { self.ajax.abort(); }
                                    self.ajax = $.ajax({
                                        url: $form.attr('action'),
                                        type: $form.attr('method'),
                                        data: data,
                                        beforeSend: function() {
                                            $this.closest('.ct-form-dates').addClass('loading');
                                            $('.step-actions, .footer-actions').addClass('disabled');
                                            $('.datepicker').hide();
                                        },
                                        success: function success(html) {
                                            var cmdStep2 = $(html).find('#cmd-step2');
                                            $('#operation_order__token').val(formToken);
                                            $('#cmd-step2').html(cmdStep2.html());
                                            self.createDatepicker();
                                            self.createTimepicker();
                                            $this.closest('.ct-form-dates').removeClass('loading');

                                            self.minMaxPerDay();

                                            AppBack.Order.validatorDates();
                                            $(".form-dates input").keydown(false);
                                            $('.step-actions, .footer-actions').removeClass('disabled');
                                        }
                                    });
                                }
                                if($(this).parent().hasClass( "halfday" )) {
                                    AppBack.Order.validatorHalfDay();
                                }
                            }
                        });
                        //$('.spe-timepicker.time-start').timepicker('setTime', minHour+':'+minMinutes);
                        if(!$('.editing').length) {
                            if(minHour != null && minMinutes != null) {
                                if(!$('#cmd-step2').hasClass('tStart-init')) {
                                    $('.spe-timepicker.time-start').val(minHour+':'+minMinutes).attr('value', minHour+':'+minMinutes);
                                    $('#cmd-step2').addClass('tStart-init')
                                }
                            }
                        }

                        // END TIME INPUT
                        minHour = minMinutes = maxHour = maxMinutes = null;
                        if (configTimepicker['end']['minHour'] != undefined) {
                            minHour = configTimepicker['end']['minHour'];
                        }
                        if (configTimepicker['end']['minMinutes'] != undefined) {
                            minMinutes = configTimepicker['end']['minMinutes'];
                        }
                        if (configTimepicker['end']['maxHour'] != undefined) {
                            maxHour = configTimepicker['end']['maxHour'];
                        }
                        if (configTimepicker['end']['maxMinutes'] != undefined) {
                            maxMinutes = configTimepicker['end']['maxMinutes'];
                        }
                        $('.spe-timepicker.time-end').timepicker({
                            timeFormat: 'HH:mm',
                            interval: 30,
                            minHour: minHour,
                            minMinutes: minMinutes,
                            maxHour: maxHour,
                            maxMinutes: maxMinutes,
                            dynamic: false,
                            dropdown: true,
                            scrollbar: true,
                            change: function () {
                                var $this = $(this);
                                var formToken = $('#operation_order__token').val();
                                var $form = $(this).closest('form');
                                var data = {};
                                $form.find('input, select, textarea').each(function () {
                                    data[$(this).attr('name')] = $(this).val();
                                });
                                data['operation_order[_token]'] = '';
                                if($(this).hasClass( "end-auto" )) {
                                    if(self.ajax) { self.ajax.abort(); }
                                    self.ajax = $.ajax({
                                        url: $form.attr('action'),
                                        type: $form.attr('method'),
                                        data: data,
                                        beforeSend: function() {
                                            $this.closest('.ct-form-dates').addClass('loading');
                                            $('.step-actions, .footer-actions').addClass('disabled');
                                            $('.datepicker').hide();
                                        },
                                        success: function success(html) {
                                            var cmdStep2 = $(html).find('#cmd-step2');
                                            $('#operation_order__token').val(formToken);
                                            $('#cmd-step2').html(cmdStep2.html());
                                            self.createDatepicker();
                                            self.createTimepicker();
                                            $this.closest('.ct-form-dates').removeClass('loading');

                                            AppBack.Order.validatorDates();
                                            $(".form-dates input").keydown(false);
                                            $('.step-actions, .footer-actions').removeClass('disabled');
                                        }
                                    });
                                }
                                if($(this).parent().hasClass( "halfday" )) {
                                    AppBack.Order.validatorHalfDay();
                                }
                            }
                        });
                        //$('.spe-timepicker.time-end').timepicker('setTime', maxHour+':'+maxMinutes);
                        if(!$('.editing').length) {
                            if(maxHour != null && maxMinutes != null) {
                                if(!$('#cmd-step2').hasClass('tEnd-init')) {
                                    $('.spe-timepicker.time-end').val(maxHour+':'+maxMinutes).attr('value', maxHour+':'+maxMinutes);
                                    $('#cmd-step2').addClass('tEnd-init');
                                }
                            }
                        }
                    }
                }
            },
            addCustomShop: function()
            {
                var self = this;
                $(document).on('click', '.command-type-animation .add-custom-shop, .command-type-refbin .add-custom-shop', {}, function () {
                    var operationId = $('#operation_order_operationId').val();
                    console.log(operationId);
                    $.ajax({
                        url: '/shop-api/api/order-custom-shop/' + operationId,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass("loading");
                        },
                        success: function success(html) {
                            $('.command-shop').hide();
                            $(".add-custom-shop").hide();
                            $('.wrapper-custom-shop').replaceWith(html);
                            $('.wrapper-custom-shop').fadeIn();
                            $('.furniture-container-wrapper').removeClass("loading");
                        }
                    });
                });

                $(document).on('click', '.command-type-animation .save-custom-shop, .command-type-refbin .save-custom-shop', {}, function () {
                    var operationId = $('#operation_order_operationId').val();
                    var formToken = $('#order_custom_shop').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    $.ajax({
                        url: '/shop-api/api/order-custom-shop/' + operationId,
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.furniture-container-wrapper').addClass("loading");
                        },
                        success: function success(html) {
                            var formToken = $('#operation_order__token').val();
                            var $form = $("form[name='operation_order']");
                            var data = {};
                            $form.find('input, select, textarea').each(function () {
                                if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                                    if($(this).prop('checked') == true) {
                                        data[$(this).attr('name')] = $(this).val();
                                    }
                                }
                                else {
                                    data[$(this).attr('name')] = $(this).val();
                                }
                            });
                            data['operation_order[_token]'] = '';
                            data['operation_order[operationId]'] = operationId;
                            data['operation_order[shop]'] = html['shopId'];
                            data['operation_order[shopSign]'] = html['shopSignId'];
                            data['operation_order[region]'] = html['regionId'];
                            $.ajax({
                                url: $form.attr('action'),
                                type: $form.attr('method'),
                                data: data,
                                beforeSend: function() {
                                    $('.command-filters').addClass('loading');
                                },
                                success: function success(html) {
                                    var cmdStep1 = $(html).find('#cmd-step1');
                                    $('#operation_order__token').val(formToken);
                                    $('#cmd-step1').html(cmdStep1.html());
                                    //self.init();
                                    AppBack.Dropdown.create('.command-filters select', '.command-filters');
                                    $('#shop-infos').addClass('editable');
                                    $('.command-filters').removeClass('loading');
                                    $('#cmd-step1').find('.filter-reset').css('display','inline-flex');
                                    $form.find('#shop-infos input').each(function () {
                                        if($(this).val() == '') {
                                        }
                                    });

                                    $('.step-tabs .step-item.active + .step-item').addClass('clickable');

                                }
                            });

                            $('.command-shop').show();
                            $(".add-custom-shop").show();
                            $('.wrapper-custom-shop').hide();
                            $('.furniture-container-wrapper').removeClass("loading");
                            $('.command-filters').removeClass("loading");
                        }
                    });
                });

                // Vider le custom shop
                $(document).on('click', '.remove-custom-shop', function () {
                    $('.wrapper-custom-shop').empty().hide();
                    $(".add-custom-shop").show();
                    $('.command-shop').fadeIn();
                });
            },
        },
        OrderFurniture: {
            selectors: {},

            valid: true,

            init: function () {
                var self = this;

                $("#user1_shopsInCharge").select2({
                    multiple: 'multiple'
                });

                self.Autocomplete();
                self.changeShop();
                self.changeShopsign();
                self.changeOrderRegion();
                self.changeShopInfos();
                self.changDeliveryeDate();
                self.changePreviewDeliveryeDate();
                self.furnitureCalendar();
                self.furnitureRefresh();
                self.furnitureSubmitStep();
                self.Picker.createDatepickerV3();
                self.keepDatesFiltered();
                self.addCustomShop();

                $(".command-container form[name='operation_order_furniture']").submit(function() {
                    self.validatorDates(true);

                    if(self.valid) {
                        $(this).find('.footer-actions button').attr('disabled', 'disabled');
                    }
                    return self.valid;
                });

                $(".form-dates input").keydown(false);

                let url = new URL(window.location.href);

                if(window.location.search != '') {
                    if(!url.searchParams.get("furnituresSelected")) {
                        $('.step-1 .filter-reset').css('display', 'inline-flex');
                    }
                }
                
                document.querySelectorAll(".furniture-pagination").forEach(function(pagination) {
                    pagination.querySelectorAll("a").forEach(function(link) {
                        let originalString = link.getAttribute("href");
                        let newString = "";

                        let substringToRemove1 = "?furnituresSelected=true&";
                        if(originalString.includes(substringToRemove1)) {
                            newString = originalString.replace(substringToRemove1, "?");
                        }
                        else {
                            let substringToRemove2 = "?furnituresSelected=true";
                            newString = originalString.replace(substringToRemove2, "");

                            let substringToRemove3 = "&furnituresSelected=true";
                            newString = newString.replace(substringToRemove3, "");
                        }

                        link.setAttribute("href", newString);
                    });
                });

                if(url.searchParams.get("furnituresSelected")) {
                    setTimeout(function() {
                        $(".next-step").click();
                        $(".furniture-container-wrapper").removeClass("refresh");
                    }, 1500);
                }

            },

            keepDatesFiltered: function() {
                if(localStorage.getItem("furnitureOrderStart") != null) {
                    $("#operation_order_furniture_deliveryDateCustom").val(localStorage.getItem("furnitureOrderStart"));
                    localStorage.removeItem("furnitureOrderStart");

                    if(localStorage.getItem("furnitureOrderEnd") != null) {
                        $("#operation_order_furniture_recoveryDateCustom").val(localStorage.getItem("furnitureOrderEnd"));
                        localStorage.removeItem("furnitureOrderEnd");
                    }
                }
            },

            changeShop: function()
            {
                var self = this;

                $(document).on('change', '#operation_order_furniture_shop', {}, function () {
                    var formToken = $('#operation_order_furniture__token').val();
                    var $form = $("form[name='operation_order_furniture']");
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[shopInfoName]'] = '';
                    data['operation_order_furniture[shopInfoAddress]'] = '';
                    data['operation_order_furniture[shopInfoPhone]'] = '';
                    data['operation_order_furniture[shopInfoMail]'] = '';
                    data['operation_order_furniture[formStep]'] = 2;

                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order_furniture__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_furniture_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').addClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('#cmd-step1').find('.filter-reset').css('display','inline-flex');
                            $form.find('#shop-infos input').each(function () {
                                if($(this).val() == '') {
                                    //$(this).attr('placeholder', $(this).closest('[data-empty]').attr('data-empty'));
                                }
                            });

                            $('.step-tabs .step-item.active + .step-item').addClass('clickable');
                        }
                    });
                });

            },

            changeShopInfos: function()
            {
                var self = this;

                $(document).on('click', '#shop-infos .close-edit', {}, function () {
                    var formToken = $('#operation_order_furniture__token').val();
                    var $form = $("form[name='operation_order_furniture']");
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[formStep]'] = 2;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order_furniture__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_furniture_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').addClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('#cmd-step1').find('.filter-reset').css('display','inline-flex');
                            $('.step-tabs .step-item.active + .step-item').addClass('clickable');
                        }
                    });
                });

            },

            changeShopsign: function()
            {
                var self = this;

                $(document).on('change', '#operation_order_furniture_shopSign', {}, function () {
                    var formToken = $('#operation_order_furniture__token').val();
                    var $form = $("form[name='operation_order_furniture']");
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[formStep]'] = 2;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order_furniture__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_furniture_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            $form.find('#shop-infos input').each(function () {
                                $(this).val('');
                            });
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').removeClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('#cmd-step1').find('.filter-reset').css('display','inline-flex');
                        }
                    });
                });

            },

            changeOrderRegion: function()
            {
                var self = this;

                $(document).on('change', '#operation_order_furniture_region', {}, function () {
                    var formToken = $('#operation_order_furniture__token').val();
                    var $form = $("form[name='operation_order_furniture']");
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                            /*else {
                                data[$(this).attr('name')] = '';
                            }*/
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[formStep]'] = 2;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep1 = $(html).find('#cmd-step1');
                            $('#operation_order_furniture__token').val(formToken);
                            $('#cmd-step1').html(cmdStep1.html());
                            $( "#operation_order_furniture_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            $form.find('#shop-infos input').each(function () {
                                $(this).val('');
                            });
                            AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            $('#shop-infos').removeClass('editable');
                            $('.command-filters').removeClass('loading');
                            $('#cmd-step1').find('.filter-reset').css('display','inline-flex');
                        }
                    });
                });

            },

            validatorDates: function(onSubmit)
            {
                var self = this;
                self.valid = true;

                const fromDate = $('.date-start').datepicker('getDate');
                const toDate = $('.date-end').datepicker('getDate');
                const today = new Date();
                const diffToday = (fromDate - today) / (1000 * 60 * 60 * 24);
                $('.date-start').removeClass('error');
                $('.date-end').removeClass('error');
                $('.error-form').hide();

                // Si date de début n'est pas dans l'intervalle du delivery delay
                const deliveryDelay = parseInt($('[data-delivery]').attr('data-delivery'));


                if( diffToday < deliveryDelay) {
                    $('.date-start').addClass('error');
                    $('.error-until').show();
                    self.valid = false;
                }

                if ( toDate ){
                    //date de fin < au début
                    if( toDate < fromDate ) {
                        $('.date-end').addClass('error');
                        $('.error-date').show();
                        self.valid = false;

                        return false;
                    }

                    const durationType = $('[data-durationtype]').attr('data-durationtype');
                    const duration = parseInt($('[data-duration]').attr('data-duration'));
                    const durationMin = parseInt($('[data-duration-min]').attr('data-duration-min'));

                    let maxDurationDate = new Date(fromDate.getTime());
                    let minDurationDate = new Date(fromDate.getTime());

                    switch ( durationType ){
                        case 'month':
                            maxDurationDate.setMonth(fromDate.getMonth()+duration);
                            minDurationDate.setMonth(fromDate.getMonth()+durationMin);
                            break;

                        case 'day':
                            maxDurationDate.setDate(fromDate.getDate()+duration);
                            minDurationDate.setDate(fromDate.getDate()+durationMin);
                            break;
                    }

                    // Si la durée est insufisante
                    if ( durationMin ){
                        if( toDate < minDurationDate) {
                            $('.date-end').addClass('error');
                            $('.error-min-duration').show();
                            self.valid = false;
                        }
                    }
                }


                return self.valid;
            },

            Autocomplete: function () {
                var self = this;
                var formToken = $('#operation_order_furniture__token').val();
                //var $form = $(this).closest('form');
                var $form = $("form[name='operation_order_furniture']");
                var data = {};
                $form.find('select').each(function () {
                    data[$(this).attr('name')] = $(this).val();
                });
                data['operation_order_furniture[_token]'] = '';
                data['operation_order_furniture[operationId]'] =  $('#operation_order_furniture_operationId').val();
                var dataParams = jQuery.param(data);
                $("#operation_order_furniture_shopAutocomplete").autocomplete({
                    source: '/autocomplete/search-order-shop/?' + dataParams,
                    minLength: 2,
                    appendTo: '.autocomplete-list',
                    search: function() {
                        $(this).closest('.autocomplete-field').addClass('loading');
                    },
                    response: function(event, ui) {
                        $(this).closest('.autocomplete-field').removeClass('loading');
                        if (ui.content.length === 0) {
                            var noResult = { value:"", label: $(this).closest('.autocomplete-field').attr('data-empty') };
                            ui.content.push(noResult);
                        }
                    },
                    select: function( event, ui ) {
                        var formToken = $('#operation_order_furniture__token').val();
                        var $form = $(this).closest('form');
                        var data = {};
                        $form.find('input, select, textarea').each(function () {
                            if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                                if($(this).prop('checked') == true) {
                                    data[$(this).attr('name')] = $(this).val();
                                }
                                /*else {
                                    data[$(this).attr('name')] = '';
                                }*/
                            }
                            else {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        });
                        data['operation_order_furniture[_token]'] = '';
                        data['operation_order_furniture[shop]'] = ui.item.id;
                        data['operation_order_furniture[shopSign]'] = ui.item.shopSignId;
                        data['operation_order_furniture[region]'] = ui.item.regionId;
                        $.ajax({
                            url: $form.attr('action'),
                            type: $form.attr('method'),
                            data: data,
                            beforeSend: function() {
                                $('.command-filters').addClass('loading');
                            },
                            success: function success(html) {
                                var cmdStep1 = $(html).find('#cmd-step1');
                                $('#operation_order_furniture__token').val(formToken);
                                $('#cmd-step1').html(cmdStep1.html());
                                //self.init();
                                AppBack.Dropdown.create('.command-filters select', '.command-filters');
                                $('#shop-infos').addClass('editable');
                                $('.command-filters').removeClass('loading');
                                $('#cmd-step1').find('.filter-reset').css('display','inline-flex');
                                $form.find('#shop-infos input').each(function () {
                                    if($(this).val() == '') {
                                    }
                                });

                                $('.step-tabs .step-item.active + .step-item').addClass('clickable');

                            }
                        });
                    }
                });
            },
            changDeliveryeDate: function()
            {
                var self = this;

                $(document).on('change', '#operation_order_furniture_deliveryDateCustom', {}, function () {
                    let $this = $(this);
                    $(".datepicker").remove();
                    var dataStep = 3;
                    var formToken = $('#operation_order_furniture__token').val();
                    var $form = $("form[name='operation_order_furniture']");
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[formStep]'] = dataStep;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $this.closest('.command-step').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep = $(html).find('.command-step.step-' + dataStep);
                            var otherFields = $(html).find('#order-other-fields');
                            $('#operation_order_furniture__token').val(formToken);
                            $('.command-step.step-' + dataStep).html(cmdStep.html());
                            $('#order-other-fields').html(otherFields.html());
                            self.Picker.init();
                            //AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            //$('#shop-infos').removeClass('editable');
                            $('.command-step.step-' + dataStep).find('.filter-reset').css('display','inline-flex');
                            setTimeout(function() {
                                $('.command-step').removeClass('loading');
                            }, 300);
                            $(".form-furnitures-recovery .form-date").removeClass("disabled");
                        },
                        complete: function(html) {
                            var deliveryDateSplit = $('.date-start').val().split('-'),
                                dateDay = deliveryDateSplit[0],
                                dateMonth = deliveryDateSplit[1],
                                dateYear = deliveryDateSplit[2];
                            //var newDateString = dateDay + '/' + dateMonth + '/' + dateYear;
                            var newDateString = dateDay + '-' + dateMonth + '-' + dateYear;
                            $('.table-custom .form-delivery-date').each(function() {
                                $(this).html(newDateString);
                            });
                        }
                    });
                });

                $(document).on('change', '#operation_order_furniture_recoveryDateCustom', {}, function () {
                    let $this = $(this);
                    $(".datepicker").remove();
                    var dataStep = 3;
                    var recoveryDate = $(this).val();
                    var formToken = $('#operation_order_furniture__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[formStep]'] = dataStep;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $this.closest('.command-step').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep = $(html).find('#cmd-step' + dataStep);
                            var otherFields = $(html).find('#order-other-fields');
                            $('#operation_order_furniture__token').val(formToken);
                            $('#cmd-step' + dataStep).html(cmdStep.html());
                            $('#order-other-fields').html(otherFields.html());
                            self.Picker.init();
                            //AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            //$('#shop-infos').removeClass('editable');
                            $('.command-step.step-' + dataStep).find('.filter-reset').css('display','inline-flex');

                            setTimeout(function() {
                                $('.command-step').removeClass('loading');
                            }, 300);
                            $(".form-furnitures-recovery .form-date").removeClass("disabled");
                        },
                        complete: function(html) {

                            var deliveryDateSplit = $('.date-start').val().split('-'),
                                dateDay = deliveryDateSplit[0],
                                dateMonth = deliveryDateSplit[1],
                                dateYear = deliveryDateSplit[2];
                            //var newDateString = dateDay + '/' + dateMonth + '/' + dateYear;
                            var newDateString = dateDay + '-' + dateMonth + '-' + dateYear;
                            $('.table-custom .form-delivery-date').each(function() {
                                $(this).html(newDateString);
                            });

                            var recoveryDateSplit = recoveryDate.split('-'),
                                dateDay = recoveryDateSplit[0],
                                dateMonth = recoveryDateSplit[1],
                                dateYear = recoveryDateSplit[2];
                            //var newDateString = dateYear + '-' + dateMonth + '-' + dateDay;
                            var newDateString =  dateDay + '-' + dateMonth + '-' + dateYear;
                            //$('#operation_order_recoveryDate').val(dateYear + '-' + dateMonth + '-' + dateDay);
                            $('.table-custom .form-control.recoveryDate').each(function() {
                                $(this).val(recoveryDate);
                            });
                        }
                    });
                });
            },
            changePreviewDeliveryeDate: function()
            {
                var self = this;
                $(document).on('change', '#default_filter_list_furnitureOrderStart', {}, function () {
                    let $this = $(this);
                    $(".datepicker").remove();
                    var dataStep = 1;
                    var formToken = $('#default_filter_list__token').val();
                    var $form = $("form[name='default_filter_list']");
                    var data = {};

                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['default_filter_list[_token]'] = '';
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $this.closest('.command-step').addClass('loading');
                            //$('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var headerFilters = $(html).find('.operation-filters.step-1');
                            $('.operation-filters.step-1').html(headerFilters.html());
                            self.Picker.createDatepickerV3();
                        },
                        complete: function(html) {
                            /*
                            var headerFilters = $(html).find('.operation-filters.step-1');
                            console.log(headerFilters.html());
                            $('.operation-filters.step-1').html(headerFilters.html());
                            self.Picker.createDatepickerV3();
                            $('#default_filter_list__token').val(formToken);
                            */
                        }
                    });
                });

                $(document).on('change', '#operation_order_furniture_recoveryDateCustom', {}, function () {
                    let $this = $(this);
                    $(".datepicker").remove();
                    var dataStep = 3;
                    var recoveryDate = $(this).val();
                    var formToken = $('#operation_order_furniture__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });

                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[formStep]'] = dataStep;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $this.closest('.command-step').addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep = $(html).find('#cmd-step' + dataStep);
                            var otherFields = $(html).find('#order-other-fields');
                            $('#operation_order_furniture__token').val(formToken);
                            $('#cmd-step' + dataStep).html(cmdStep.html());
                            $('#order-other-fields').html(otherFields.html());
                            self.Picker.init();
                            //AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            //$('#shop-infos').removeClass('editable');
                            $('.command-step.step-' + dataStep).find('.filter-reset').css('display','inline-flex');

                            setTimeout(function() {
                                $('.command-step').removeClass('loading');
                            }, 300);
                            $(".form-furnitures-recovery .form-date").removeClass("disabled");
                        },
                        complete: function(html) {

                            var deliveryDateSplit = $('.date-start').val().split('-'),
                                dateDay = deliveryDateSplit[0],
                                dateMonth = deliveryDateSplit[1],
                                dateYear = deliveryDateSplit[2];
                            //var newDateString = dateDay + '/' + dateMonth + '/' + dateYear;
                            var newDateString = dateDay + '-' + dateMonth + '-' + dateYear;
                            $('.table-custom .form-delivery-date').each(function() {
                                $(this).html(newDateString);
                            });

                            var recoveryDateSplit = recoveryDate.split('-'),
                                dateDay = recoveryDateSplit[0],
                                dateMonth = recoveryDateSplit[1],
                                dateYear = recoveryDateSplit[2];
                            //var newDateString = dateYear + '-' + dateMonth + '-' + dateDay;
                            var newDateString =  dateDay + '-' + dateMonth + '-' + dateYear;
                            //$('#operation_order_recoveryDate').val(dateYear + '-' + dateMonth + '-' + dateDay);
                            $('.table-custom .form-control.recoveryDate').each(function() {
                                $(this).val(recoveryDate);
                            });
                        }
                    });
                });
            },
            furnitureCalendar: function()
            {
                var self = this;
                $(document).on('click', '.furniture-teaser .calendar-prev, .product-calendar .calendar-prev', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var orderId = $(this).attr('data-order');
                    var furnitureId = $(this).attr('data-furniture');
                    var startDate = $(this).attr('data-start');
                    var endDate = $(this).attr('data-end');
                    var urlApi = '/orderapi/furniture-calendar/' + opeId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    if(orderId.length > 0) {
                        urlApi = '/orderapi/furniture-calendar-2/' + orderId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    }
                    $.ajax({
                        url: urlApi,
                        //type: 'get',
                        //data: data,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass('loading');
                            //$('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            $('.product-calendar.item-' + furnitureId).replaceWith(html);
                            //self.furnitureCalendar();
                            $('.furniture-container-wrapper').removeClass('loading');

                            let newDate = new Date(startDate * 1000);
                            newDate = newDate.toISOString().split('T')[0];
                            $('.product-calendar.item-' + furnitureId).find(".calendar-input-date").val(newDate);
                        }
                    });
                    //}
                });

                $(document).on('click', '.furniture-teaser .calendar-next, .product-calendar .calendar-next', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var orderId = $(this).attr('data-order');
                    var furnitureId = $(this).attr('data-furniture');
                    var startDate = $(this).attr('data-start');
                    var endDate = $(this).attr('data-end');
                    var urlApi = '/orderapi/furniture-calendar/' + opeId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    if(orderId.length > 0) {
                        urlApi = '/orderapi/furniture-calendar-2/' + orderId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    }
                    $.ajax({
                        url: urlApi,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.product-calendar.item-' + furnitureId).replaceWith(html);
                            //self.furnitureCalendar();
                            $('.furniture-container-wrapper').removeClass('loading');

                            let newDate = new Date(startDate * 1000);
                            newDate = newDate.toISOString().split('T')[0];
                            $('.product-calendar.item-' + furnitureId).find(".calendar-input-date").val(newDate);
                        }
                    });
                    //}
                });

                // Changement de date avec l'input date
                $(document).on('change', '.product-calendar .calendar-input-date', {}, function (e) {
                    e.preventDefault();
                    let $this = $(this);

                    let opeId = $(this).closest(".product-calendar").attr('data-ope');
                    let orderId = $(this).closest(".product-calendar").attr('data-order');
                    let furnitureId = $(this).closest(".product-calendar").attr('data-furniture');

                    let startDate =  $(this).closest(".product-calendar").attr('data-start');
                    let endDate =  $(this).closest(".product-calendar").attr('data-end');
                    let isStartDate = true;
                    let dateInput = $(this).val();
                    if($(this).parent().hasClass('startdate')) {
                        startDate = new Date($(this).val()).getTime() / 1000;
                    }
                    else {
                        endDate = new Date($(this).val()).getTime() / 1000;
                        isStartDate = false;
                    }

                    let urlApi = '/orderapi/furniture-calendar/' + opeId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    if(orderId.length > 0) {
                        urlApi = '/orderapi/furniture-calendar-2/' + orderId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    }

                    $.ajax({
                        url: urlApi,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.product-calendar.item-' + furnitureId).replaceWith(html);
                            $('.furniture-container-wrapper').removeClass('loading');
                            if(isStartDate == true) {
                                $('.product-calendar.item-' + furnitureId).find(".calendar-custom.startdate .calendar-input-date").val(dateInput);
                            }
                            else {
                                $('.product-calendar.item-' + furnitureId).find(".calendar-custom.enddate .calendar-input-date").val(dateInput);
                            }
                        }
                    });
                    //}
                });

                $(document).on('click', '.furniture-teaser .calendar-add', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var furnitureId = $(this).attr('data-furniture');
                    var startDate = $(this).attr('data-start');
                    var endDate = $(this).attr('data-end');
                    $.ajax({
                        url: '/orderapi/furniture-calendar-add/' + opeId + '/' + furnitureId + '/' + startDate+ '/' + endDate,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.product-calendar.item-' + furnitureId).replaceWith(html);
                            self.refrehCartList(opeId);
                            $('.product-calendar.item-' + furnitureId).closest(".furniture-teaser").addClass("isInOrder");
                            $(".furniture-refresh").siblings(".field-error").hide();
                        }
                    });
                });

                $(document).on('click', '.furniture-teaser .calendar-delete', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var furnitureId = $(this).attr('data-furniture');
                    var startDate = $(this).attr('data-start');
                    var endDate = $(this).attr('data-end');
                    $.ajax({
                        url: '/orderapi/furniture-calendar-delete/' + opeId + '/' + furnitureId + '/' + startDate+ '/' + endDate,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.product-calendar.item-' + furnitureId).replaceWith(html);
                            self.refrehCartList(opeId);
                            //self.furnitureCalendar();
                            $('.product-calendar.item-' + furnitureId).closest(".furniture-teaser").removeClass("isInOrder");
                        },
                    });
                });

                $(document).on('click', '.furniture-teaser .calendar-show', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var orderId = $(this).attr('data-order');
                    var furnitureId = $(this).attr('data-furniture');
                    var startDate = $(this).attr('data-start');
                    var endDate = $(this).attr('data-end');
                    var urlApi = '/orderapi/furniture-calendar/' + opeId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    if(orderId.length > 0) {
                        urlApi = '/orderapi/furniture-calendar-2/' + orderId + '/' + furnitureId + '/' + startDate+ '/' + endDate;
                    }
                    $.ajax({
                        url: urlApi,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('.product-calendar.item-' + furnitureId).replaceWith(html);
                            $('.furniture-container-wrapper').removeClass('loading');

                            let newDate = new Date(startDate * 1000);
                            newDate = newDate.toISOString().split('T')[0];
                            $('.product-calendar.item-' + furnitureId).find(".calendar-input-date").val(newDate);
                        },
                    });
                });

                // Page d'une commande opération
                $(document).on('click', '.furniture-container-wrapper #furnitures-list .calendar-delete', {}, function (e) {
                    e.preventDefault();
                    var opeId = $(this).attr('data-ope');
                    var furnitureId = $(this).attr('data-furniture');
                    $.ajax({
                        url: '/orderapi/furniture-list-delete/' + opeId + '/' + furnitureId,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass('loading');
                        },
                        success: function success(html) {
                            $('#furnitures-list').replaceWith(html);
                            $('.furniture-container-wrapper').removeClass('loading')
                            if($('.product-calendar.item-' + furnitureId).length) {
                                $('.product-calendar.item-' + furnitureId + ' .calendar-delete').trigger( "click" );
                            }
                            else {
                                self.furnitureCalendar();
                            }
                        },
                    });
                });

            },
            refrehCartList: function(opeId) {
                var self = this;
                $.ajax({
                    url: '/orderapi/furniture-calendar-list/' + opeId,
                    beforeSend: function() {

                    },
                    success: function success(html) {
                        $('#furnitures-list').replaceWith(html);
                        $('.furniture-container-wrapper').removeClass('loading').addClass("refresh");
                        //self.furnitureCalendar();
                    }
                });
            },
            furnitureRefresh: function() {
                $(".furniture-refresh").click(function(e) {
                    if($("#furnitures-list .checklist-item").length > 0) {
                        e.preventDefault();
                        let url = new URL(window.location.href);
                        if(!url.searchParams.get("furnituresSelected")) {
                            url.searchParams.append("furnituresSelected", true);
                        }

                        $(this).siblings(".field-error").hide();

                        if($("#default_filter_list_furnitureOrderStart").val() != "") {
                            localStorage.setItem("furnitureOrderStart", $("#default_filter_list_furnitureOrderStart").val());

                            if($("#default_filter_list_furnitureOrderEnd").val() != "") {
                                localStorage.setItem("furnitureOrderEnd", $("#default_filter_list_furnitureOrderEnd").val());
                            }
                        }

                        window.location.href = url.href;
                    }
                    else {
                        $(this).siblings(".field-error").hide().fadeIn(200);
                    }
                });
            },
            furnitureSubmitStep: function()
            {
                var self = this;

                $(document).on('click', '.furniture-container .next-step', {}, function (e) {
                    //e.preventDefault();
                    var dataStep = $(this).attr("data-step");
                    if ( $('.error').length ){
                        return;
                    }

                    let url = new URL(window.location.href);
                    if(url.searchParams.get("furnituresSelected")) {
                        url.searchParams.delete("furnituresSelected");
                        window.history.replaceState({}, "", url.href);
                        $(".flash-furnitures").hide();
                        $(".furniture-container-wrapper").removeClass("loading");
                    }
                    
                    $('#operation_order_furniture_formStep').val(dataStep);
                    //if(dataStep == 3) {
                    var formToken = $('#operation_order_furniture__token').val();
                    //var $form = $(this).closest('form');
                    var $form = $("form[name='operation_order_furniture']");
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    data['operation_order_furniture[_token]'] = '';
                    data['operation_order_furniture[formStep]'] = dataStep;
                    $.ajax({
                        url: $form.attr('action'),
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-step.step-' + dataStep).addClass('loading');
                            $('.step-tabs .step-item.active + .step-item').removeClass('clickable');
                        },
                        success: function success(html) {
                            var cmdStep = $(html).find('.command-step.step-' + dataStep);
                            //var cmdStep = $(html).find('#cmd-content');
                            $('#operation_order_furniture__token').val(formToken);
                            $('.command-step.step-' + dataStep).html(cmdStep.html());
                            //$('#cmd-content').html(cmdStep.html());
                            $( "#operation_order_furniture_shopAutocomplete" ).val('');
                            self.Autocomplete();
                            self.Picker.init();
                            //AppBack.Dropdown.create('.command-filters select', '.command-filters');
                            //$('#shop-infos').removeClass('editable');
                            $('.command-step.step-' + dataStep).removeClass('loading');
                            $('.command-step.step-' + dataStep).find('.filter-reset').css('display','inline-flex');
                        }
                    });

                });

            },
            Picker: {
                selectors: {},

                init: function () {
                    var self = this;
                    self.createDatepicker();
                    self.createDatepickerV2();
                    self.createDatepickerV3();
                    self.minMaxPerDay();
                },

                minMaxPerDay: function() {
                    var minTime = $('.minMaxPerDay').attr('data-min'),
                        maxTime = $('.minMaxPerDay').attr('data-max');
                    if(minTime != '' && maxTime != '' && !isNaN(minTime) && !isNaN(maxTime) && minTime == maxTime) {
                        if(minTime != 0 && maxTime != 0) {
                            var element = $('.spe-timepicker.time-start');
                            var perDay = parseFloat(minTime);

                            var hours = Math.floor(perDay);
                            var decimal = perDay - Math.floor(perDay);
                            decimal = decimal * 60;

                            element.each(function() {
                                var finalTime = $(this).timepicker('getTime');
                                if(finalTime != undefined) {
                                    finalTime.setHours(finalTime.getHours() + hours);
                                    finalTime.setMinutes(finalTime.getMinutes() + decimal);

                                    $(this).closest('.ct-form-dates').find('.time-end').val($(this).timepicker().format(finalTime));
                                }
                            });
                        }
                    }
                },

                createDatepicker: function()
                {
                    var self = this;

                    var availableDates = $('#operation_order_furniture_availableDates').val();
                    if(availableDates != null) {
                        availableDates = $.parseJSON(availableDates);
                        $('.spe-datepiker').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            beforeShowDay: function (date) {
                                var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                if (availableDates.indexOf(allDates) != -1) {
                                    return true;
                                }
                                else {
                                    return false;
                                }
                            }
                        });
                        $(document).on('change', '.spe-datepiker', function(){
                            if($(this).parent().hasClass( "halfday" )) {
                                AppBack.Order.validatorHalfDay();
                            }
                        });
                    }
                },
                createDatepickerV2: function()
                {
                    var self = this;

                    var minBeginActionDate = $('#operation_order_furniture_minBeginActionDate').val();
                    var maxBeginActionDate = $('#operation_order_furniture_maxBeginActionDate').val();

                    var unavailableDateStart = $('#operation_order_furniture_unavailableDateStart').val();
                    if(minBeginActionDate != null && maxBeginActionDate != null) {
                        unavailableDateStart = $.parseJSON(unavailableDateStart);
                        $('.spe-datepiker-start').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            startDate: new Date(minBeginActionDate),
                            endDate: new Date(maxBeginActionDate),
                            beforeShowDay: function (date) {
                                if(unavailableDateStart != null) {
                                    var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    if (unavailableDateStart.indexOf(allDates) != -1) {
                                        return false;
                                    }
                                    else {
                                        return true;
                                    }
                                }
                                else {
                                    return true;
                                }
                            }
                        });
                        $(document).on('change', '.spe-datepiker-start', function(){
                            AppBack.Order.validatorDatesBin();
                        })
                    }

                    var minEndActionDate = $('#operation_order_furniture_minEndActionDate').val();
                    var maxEndActionDate = $('#operation_order_furniture_maxEndActionDate').val();

                    var unavailableDateEnd = $('#operation_order_furniture_unavailableDateEnd').val();
                    if(minEndActionDate != null && maxEndActionDate != null) {
                        unavailableDateEnd = $.parseJSON(unavailableDateEnd);
                        var recoveryDateSplit = $('#operation_order_furniture_recoveryDateCustom').val().split('-'),
                            dateDay = recoveryDateSplit[0],
                            dateMonth = recoveryDateSplit[1],
                            dateYear = recoveryDateSplit[2];

                        $('.spe-datepiker-end').each(function () {
                            if($(this).hasClass('recovery-type-2')) {
                                minEndActionDate = dateYear + '-' + dateMonth + '-' + dateDay;
                            }
                            else if($(this).hasClass('recovery-type-3')) {
                                maxEndActionDate = dateYear + '-' + dateMonth + '-' + dateDay;
                            }
                            else {
                                var dataMaxData = $(this).parent('.form-recovery-date').attr('data-max-date');
                                if(dataMaxData !== undefined) {
                                    maxEndActionDate = dataMaxData;
                                }
                            }
                            $(this).datepicker({
                                language: 'fr',
                                format: 'dd-mm-yyyy',
                                startDate: new Date(minEndActionDate),
                                endDate: new Date(maxEndActionDate),
                                beforeShowDay: function (date) {
                                    if(unavailableDateEnd != null) {
                                        var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                        if (unavailableDateEnd.indexOf(allDates) != -1) {
                                            return false;
                                        }
                                        else {
                                            return true;
                                        }
                                    }
                                    else {
                                        return true;
                                    }
                                }
                            });
                        });
                        $(document).on('change', '.spe-datepiker-end:not(.recoveryDate)', function(){
                            AppBack.Order.validatorDatesBin();
                        })
                    }
                },
                createDatepickerV3: function()
                {
                    var self = this;

                    var minBeginActionDate = $('#default_filter_list_minBeginActionDate').val();
                    var maxBeginActionDate = $('#default_filter_list_maxBeginActionDate').val();

                    var unavailableDateStart = $('#operation_order_furniture_unavailableDateStart').val();
                    if(minBeginActionDate != null && maxBeginActionDate != null) {
                        unavailableDateStart = $.parseJSON(unavailableDateStart);
                        $('.spe-datepiker-start').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            startDate: new Date(minBeginActionDate),
                            endDate: new Date(maxBeginActionDate),
                            beforeShowDay: function (date) {
                                if(unavailableDateStart != null) {
                                    var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    if (unavailableDateStart.indexOf(allDates) != -1) {
                                        return false;
                                    }
                                    else {
                                        return true;
                                    }
                                }
                                else {
                                    return true;
                                }
                            }
                        });
                    }

                    var minEndActionDate = $('#default_filter_list_minEndActionDate').val();
                    var maxEndActionDate = $('#default_filter_list_maxEndActionDate').val();
                    var unavailableDateEnd = $('#operation_order_furniture_unavailableDateEnd').val();
                    if(minEndActionDate != null && maxEndActionDate != null) {
                        unavailableDateEnd = $.parseJSON(unavailableDateEnd);
                        $('.spe-datepiker-end').datepicker({
                            language: 'fr',
                            format: 'dd-mm-yyyy',
                            startDate: new Date(minEndActionDate),
                            endDate: new Date(maxEndActionDate),
                            beforeShowDay: function (date) {
                                if(unavailableDateEnd != null) {
                                    var allDates = date.getDate() + '-' + (date.getMonth() + 1) + '-' + date.getFullYear();
                                    if (unavailableDateEnd.indexOf(allDates) != -1) {
                                        return false;
                                    }
                                    else {
                                        return true;
                                    }
                                }
                                else {
                                    return true;
                                }
                            }
                        });
                    }
                },
            },
            addCustomShop: function()
            {
                var self = this;
                $(document).on('click', '.command-type-furniture .add-custom-shop', {}, function () {
                    var operationId = $('#operation_order_furniture_operationId').val();
                    $.ajax({
                        url: '/shop-api/api/order-custom-shop/' + operationId,
                        beforeSend: function() {
                            $('.furniture-container-wrapper').addClass("loading");
                        },
                        success: function success(html) {
                            $('.command-shop').hide();
                            $(".add-custom-shop").hide();
                            $('.wrapper-custom-shop').replaceWith(html);
                            $('.wrapper-custom-shop').fadeIn();
                            $('.furniture-container-wrapper').removeClass("loading");
                        }
                    });
                });

                $(document).on('click', '.command-type-furniture .save-custom-shop', {}, function () {
                    var operationId = $('#operation_order_furniture_operationId').val();
                    var formToken = $('#order_custom_shop').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                            if($(this).prop('checked') == true) {
                                data[$(this).attr('name')] = $(this).val();
                            }
                        }
                        else {
                            data[$(this).attr('name')] = $(this).val();
                        }
                    });
                    $.ajax({
                        url: '/shop-api/api/order-custom-shop/' + operationId,
                        type: $form.attr('method'),
                        data: data,
                        beforeSend: function() {
                            $('.command-filters').addClass('loading');
                            $('.furniture-container-wrapper').addClass("loading");
                        },
                        success: function success(html) {
                            var formToken = $('#operation_order_furniture__token').val();
                            var $form = $("form[name='operation_order_furniture']");
                            var data = {};
                            $form.find('input, select, textarea').each(function () {
                                if($(this).attr('type') == 'checkbox' || $(this).attr('type') == 'radio') {
                                    if($(this).prop('checked') == true) {
                                        data[$(this).attr('name')] = $(this).val();
                                    }
                                }
                                else {
                                    data[$(this).attr('name')] = $(this).val();
                                }
                            });
                            data['operation_order_furniture[_token]'] = '';
                            data['operation_order_furniture[operationId]'] = operationId;
                            data['operation_order_furniture[shop]'] = html['shopId'];
                            data['operation_order_furniture[shopSign]'] = html['shopSignId'];
                            data['operation_order_furniture[region]'] = html['regionId'];
                            $.ajax({
                                url: $form.attr('action'),
                                type: $form.attr('method'),
                                data: data,
                                beforeSend: function() {
                                    $('.command-filters').addClass('loading');
                                },
                                success: function success(html) {
                                    var cmdStep1 = $(html).find('#cmd-step1');
                                    $('#operation_order_furniture__token').val(formToken);
                                    $('#cmd-step1').html(cmdStep1.html());
                                    //self.init();
                                    AppBack.Dropdown.create('.command-filters select', '.command-filters');
                                    $('#shop-infos').addClass('editable');
                                    $('.command-filters').removeClass('loading');
                                    $('#cmd-step1').find('.filter-reset').css('display','inline-flex');
                                    $form.find('#shop-infos input').each(function () {
                                        if($(this).val() == '') {
                                        }
                                    });

                                    $('.step-tabs .step-item.active + .step-item').addClass('clickable');

                                }
                            });

                            $('.command-shop').show();
                            $(".add-custom-shop").show();
                            $('.wrapper-custom-shop').hide();
                            $('.furniture-container-wrapper').removeClass("loading");
                            $('.command-filters').removeClass("loading");
                        }
                    });
                });
            },
        },

        ColorPicker: {
            selectors: {},

            init: function () {
                var self = this;

                $('.my-colorpicker1').colorpicker();
                $('.my-colorpicker2').colorpicker();

                $("#advertiser_dropdown, #advertiser_dropdown_mobile").change(function () {
                    var url = $(this).val();
                    window.location.replace(url);
                });
            },
        },

        Dropdown: {
                selectors: {
                    container: '.ct-dropdown',
                },

                applyTo:
                '.select-custom select'
                + ', .row-filters select:not(.select2-hidden-accessible)'
                + ', .form-translation select'
                ,

                /*init: function()
                {
                    var self = this;

                    var time = 100;
                    if($(self.applyTo).parents('.ct-search-filters').length > 0) {
                        time = 500;
                    }


                    $(window).on('load', function() {
                        setTimeout(function() { self.create(self.applyTo); }, time);
                    });

                    $('body').on('change','.content #div_profil_ens_subform select', function() { 
                        setTimeout(function() { 
                            if(!$('.content #div_profil_ens_subform select').parent().hasClass('ct-dropdown')) { 
                                self.create('.content #div_profil_ens_subform select'); 
                            } 
                        }, 500);
                    });
                },*/

                create: function(element, wrapper)
                {
                    var self = this;

                    //$.each(self.applyTo, function(i, apply){
                    //$(self.applyTo).each(function(i, apply) {
                    $(element).each(function() {
                        var $this = $(this), numberOfOptions = $(this).children('option').length;

                        $this.addClass('select-hidden');
                        $this.wrap('<div class="ct-dropdown"></div>');
                        $this.after('<div class="dropdown-label"></div>');

                        var $styledSelect = $this.next('div.dropdown-label');

                        $this.children('option').each(function() {
                            if($(this).attr('selected') == 'selected') {
                                $styledSelect.text($(this).text());
                                return false;
                            }
                            else {
                                $styledSelect.text($this.children('option').eq(0).text());
                            }
                        });

                        var $list = $('<ul />', {
                            'class': 'dropdown-list'
                        }).insertAfter($styledSelect);

                        for (var i = 0; i < numberOfOptions; i++) {
                            $('<li />', {
                                text: $this.children('option').eq(i).text(),
                                rel: $this.children('option').eq(i).val()
                            }).appendTo($list);
                        }

                        var $listItems = $list.children('li');

                        //self.change($this);
                    });

                    self.change(wrapper);
                },

                change: function(wrapper)
                {
                    var self = this;
                    var $container = $(wrapper + " " + self.selectors.container);
                    if($container.length < 1) { return; }

                    $container.each(function() {

                        var $dropdown = $(this);
                        var $select = $(this).find('select');
                        var $list = $dropdown.find('ul.dropdown-list');
                        var $listItems = $list.children('li');

                        var $styledSelect = $dropdown.find('.dropdown-label');
                        $list.children('li').each(function() {
                            if($(this).hasClass('active')) {
                                $styledSelect.html($(this).text());
                            }
                        });

                        $styledSelect.click(function(e) {
                            e.stopPropagation();

                            $('ul.dropdown-list').not($list).hide();
                            $('.ct-dropdown').not($dropdown).removeClass('open');
                            
                            if($list.is(':hidden')) {
                                $list.slideDown(200);
                                $dropdown.addClass('open');
                            }
                            else {
                                $list.slideUp(200);
                                $dropdown.removeClass('open');
                            }
                        });

                        $listItems.click(function(e) {
                            e.stopPropagation();
                            $listItems.removeClass('selected');
                            $(this).addClass('selected');
                            $styledSelect.html($(this).text()).removeClass('active');
                            $dropdown.removeClass('open');
                            $select.val($(this).attr('rel'));
                            $list.hide();

                            $select.change();
                        });

                        $(document).click(function() {
                            $styledSelect.removeClass('active');
                            $dropdown.removeClass('open');
                            $list.hide();
                        });

                    });
                },
            },
        DropdownBis: {
            selectors: {
                elt: '.dropdown-bissub'
            },

            init: function init() {
                var self = this;

                $(self.selectors.elt).find('.dropdown-label').click(function (e) {
                    e.preventDefault();

                    var $dropdown = $(this).parent(self.selectors.elt);
                    if (!$dropdown.hasClass('open')) {
                        $dropdown.find('.dropdown-list').slideDown(150);
                        $dropdown.addClass('open');
                    } else {
                        $dropdown.find('.dropdown-list').slideUp(150);
                        $dropdown.removeClass('open');
                    }
                });

                $(self.selectors.elt).clickoutside(function () {
                    $(this).removeClass('open').find('.dropdown-list').hide();
                });
            }
        },

        Quota: {
            selectors: {},

            init: function () {
                var self = this;

                $(document).on('click', '#quota_distribute_submit', {}, function () {
                    //event.preventDefault();
                    var formToken = $('#quota_distribute__token').val();
                    var $form = $(this).closest('form');
                    var data = {};
                    $form.find('input, select, textarea').each(function () {
                        data[$(this).attr('name')] = $(this).val();
                    });
                    data['quota_distribute[_token]'] = '';

                    var error = false;
                    var quotaToAttribute = data['quota_distribute[quantity]'];
                    if('allRegions' == data['quota_distribute[distributeType]']) {
                        $( ".quota-item" ).each(function() {
                            var consumed = $( this ).find( ".quota-consumed input" ).val();
                            if(consumed > quotaToAttribute) {
                                error = true;
                            }
                            else {
                                $( this ).find( ".quota-assigned input" ).val(quotaToAttribute);
                            }
                        });
                        //$('.quota-assigned input').val(quotaToAttribute);
                    }
                    else if('regionNiv' == data['quota_distribute[distributeType]'].substr(0, 9)) {
                        var regionNiv = data['quota_distribute[distributeType]'].substr(9);
                        $( ".quota-item .quota-inputs.niv-" + regionNiv).each(function() {
                            var consumed = $( this ).find( ".quota-consumed input" ).val();
                            if(consumed > quotaToAttribute) {
                                error = true;
                            }
                            else {
                                $( this ).find( ".quota-assigned input" ).val(quotaToAttribute);
                            }
                        });
                    }
                });
            }
        },

        Page: {
            selectors: {},

            init: function () {
                var self = this;

                // On récupère la balise <div> en question qui contient l'attribut « data-prototype » qui nous intéresse.
                var $container = $('div#page_attachments, div#news_attachments, div#operation_otherIllus');

                // On définit un compteur unique pour nommer les champs qu'on va ajouter dynamiquement
                var index = $container.find(':input').length;

                // On ajoute un nouveau champ à chaque clic sur le lien d'ajout.
                $('#add_attachment').click(function(e) {
                    addAttachment($container);
                    e.preventDefault(); // évite qu'un # apparaisse dans l'URL
                    return false;
                });

                // On ajoute un premier champ automatiquement s'il n'en existe pas déjà un (cas d'une nouvelle annonce par exemple).
                if (index != 0) {
                    $container.children('div').each(function(index) {
                        $(this).addClass('attachment-wrapper');
                        addDeleteLink($(this));
                    });
                }

                // La fonction qui ajoute un formulaire CategoryType
                function addAttachment($container) {
                    // Dans le contenu de l'attribut « data-prototype », on remplace :
                    // - le texte "__name__label__" qu'il contient par le label du champ
                    // - le texte "__name__" qu'il contient par le numéro du champ
                    var template = $container.attr('data-prototype')
                        .replace(/__name__label__/g, 'Fichier n°' + (index+1))
                        .replace(/__name__/g, index)
                    ;

                    // On crée un objet jquery qui contient ce template
                    var $prototype = $(template);
                    $prototype.addClass('attachment-wrapper new-attachment');
                    $prototype.find("input[type='file']").attr("accept", ".xlsx, .xls, .pptx, .ppt, .docx, .odt, .pdf, .jpg, .jpeg, .png");

                    // On ajoute au prototype un lien pour pouvoir supprimer la catégorie
                    addDeleteLink($prototype);

                    // On ajoute le prototype modifié à la fin de la balise <div>
                    $container.append($prototype);

                    // Enfin, on incrémente le compteur pour que le prochain ajout se fasse avec un autre numéro
                    index++;
                }

                // La fonction qui ajoute un lien de suppression d'une catégorie
                function addDeleteLink($prototype) {
                    // Création du lien
                    var $deleteLink = $('<a href="#" class="btn btn-danger">Supprimer</a>');

                    // Ajout du lien
                    $prototype.append($deleteLink);

                    // Ajout du listener sur le clic du lien pour effectivement supprimer la catégorie
                    $deleteLink.click(function(e) {
                        $prototype.remove();

                        e.preventDefault(); // évite qu'un # apparaisse dans l'URL
                        return false;
                    });
                }
            },
        },
    };

    $(document).ready(function () {
        AppBack.init();
    });
}(jQuery));
